html, body {
  height: 100%;
  margin: 0;
}

.App {
  min-height: 100%;
  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -70px;
}

.cell {
  z-index: 10;
  cursor: pointer;
}

.summary {
  text-indent: 2em;
}

.cell:hover {
  background-color: #f9f9f9;
}

.summary > p > a {
  text-decoration: underline;
}

footer {
  width: 100%;
  height: 70px;
}

@keyframes activeStage {
  from { background-color: #E9D8FD; }
  to { background-color: #D6BCFA; }
}

.activeStage {
  animation: activeStage 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.firstStage {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.lastStage {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pc_interests::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pc_interests {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}